export const skills = [
  {
    icon: '/langs/git.png',
    name: 'DevOps',
    tech: 'Github | Gitlab',
  },
  {
    icon: '/langs/database.png',
    name: 'Database Management System',
    tech: 'MySQL | MongoDB',
  },
  {
    icon: '/langs/netlify.png',
    name: 'Cloud Services',
    tech: 'Vercel | Netlify | Heroku | Azure',
  },
  {
    icon: '/langs/python.png',
    name: 'Programming Languages',
    tech: 'Python | C | JavaScript | PHP',
  },
  {
    icon: '/langs/vscode.png',
    name: 'IDEs',
    tech: 'Visual Studio Code | Gitpod | Replit',
  },
  {
    icon: '/langs/vite.png',
    name: 'Frontend Frameworks',
    tech: 'React | Vite.js | Gatsby | Next.js',
  },
  {
    icon: '/langs/nextjs.png',
    name: 'Backend Frameworks',
    tech: 'Node.js | Next.js',
  },
  {
    icon: '/langs/Tailwind.png',
    name: 'React Libraries',
    tech: 'Tailwind CSS | Chakra UI | Daisy UI',
  },
  {
    icon: '/langs/mui.png',
    name: 'Mobile Library',
    tech: 'Material UI',
  },
  {
    icon: '/langs/firebase.png',
    name: 'SDK',
    tech: 'Firebase',
  },
  {
    icon: '/langs/style.png',
    name: 'Css Frameworks',
    tech: 'Styled Components | Tailwind | Bootstrap',
  },
];
